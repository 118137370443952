.main-wrapper_sidebar {
  display: none; }
  @media (min-width: 1024px) {
    .main-wrapper_sidebar {
      display: block;
      background-color: #fff;
      border-right: 2px solid #f3f3f3;
      padding: 0; } }

.main-wrapper_content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0; }
  @media (min-width: 1024px) {
    .main-wrapper_content {
      background-color: #fff;
      padding-top: 30px; } }
  @media (min-width: 1024px) {
    body.homepage .main-wrapper_content {
      padding-top: 0; } }
  .main-wrapper_content_infobar {
    background-color: #474747;
    color: #fff;
    font-size: 1.1em;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 30px; }
  .main-wrapper_content_wrapper {
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 1024px) {
      .main-wrapper_content_wrapper {
        padding-left: 30px;
        padding-right: 30px; } }
  .main-wrapper_content_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px; }
    @media (min-width: 1024px) {
      .main-wrapper_content_header {
        margin-left: 30px;
        margin-right: 30px; } }
    body.homepage .main-wrapper_content_header {
      display: none; }
    .main-wrapper_content_header_facebook {
      display: none; }
      @media (min-width: 1024px) {
        .main-wrapper_content_header_facebook {
          display: block; } }
  .main-wrapper_content_heading {
    margin-left: 15px;
    margin-right: 15px; }
    @media (min-width: 1024px) {
      .main-wrapper_content_heading {
        margin-bottom: 24px;
        margin-left: 30px;
        margin-right: 30px; } }
    .main-wrapper_content_heading_title {
      color: #474747;
      display: none;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px; }
      @media (min-width: 1024px) {
        .main-wrapper_content_heading_title {
          display: block;
          font-size: 36px;
          margin-bottom: 18px; } }
      .main-wrapper_content_heading_title_counter {
        font-size: 25px;
        font-weight: 400; }
        @media (min-width: 1024px) {
          .main-wrapper_content_heading_title_counter {
            display: none; } }
    .main-wrapper_content_heading_subtitle {
      color: #474747;
      font-size: 15px;
      line-height: 1.4;
      margin-top: 0; }
      @media (min-width: 1024px) {
        .main-wrapper_content_heading_subtitle {
          color: #848181; } }
  .main-wrapper_content_products {
    background-color: #fff; }
    .main-wrapper_content_products_title {
      color: #474747;
      border-bottom: 2px solid #f3f3f3;
      font-size: 26px;
      margin-bottom: 20px;
      padding-bottom: 18px;
      padding-top: 18px;
      text-align: center;
      text-transform: uppercase; }
    .main-wrapper_content_products_controls-bottom {
      display: none; }
      @media (min-width: 1024px) {
        .main-wrapper_content_products_controls-bottom {
          display: block; } }
    .main-wrapper_content_products .products_list {
      list-style: none;
      margin: 0;
      padding: 0; }
      body.homepage .main-wrapper_content_products .products_list {
        display: flex;
        flex-wrap: wrap; }
      .main-wrapper_content_products .products_list_horizontal {
        display: none; }
        @media (max-width: 1023px) {
          .main-wrapper_content_products .products_list_horizontal {
            display: none !important; } }
      @media (max-width: 1023px) {
        .main-wrapper_content_products .products_list_vertical {
          display: block !important; } }
      @media (min-width: 1024px) {
        .main-wrapper_content_products .products_list_vertical {
          display: flex;
          flex-wrap: wrap; } }
      .main-wrapper_content_products .products_list_item {
        width: 100%; }
        .main-wrapper_content_products .products_list_item:not(:last-child) {
          margin-bottom: 30px; }
        @media (min-width: 1024px) {
          .main-wrapper_content_products .products_list_item {
            width: calc(100% / 3); } }
        @media (min-width: 1440px) {
          .main-wrapper_content_products .products_list_item {
            width: 25%; } }
      .main-wrapper_content_products .products_list.products_list_horizontal .products_list_item {
        width: 100%; }
  .main-wrapper_content_boxes {
    margin-top: 25px; }
    @media (min-width: 600px) and (max-width: 1023px) {
      .main-wrapper_content_boxes {
        display: flex; } }
    @media (min-width: 1024px) {
      .main-wrapper_content_boxes {
        display: none; } }
    .main-wrapper_content_boxes_article {
      background-color: #fff;
      margin-left: 15px;
      margin-right: 15px;
      padding-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 20px; }
      .main-wrapper_content_boxes_article:not(:last-type) {
        margin-bottom: 18px; }

.main-wrapper_badges {
  margin-top: 10px;
  margin-left: -190px;
  position: fixed;
  width: 180px; }
  .main-wrapper_badges img {
    display: block;
    max-width: 180px;
    margin-bottom: 10px;
    opacity: .6; }
    .main-wrapper_badges img:hover {
      opacity: 1; }

.article-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .article-list_item:not(:last-child) {
    margin-bottom: 12px; }
  .article-list_item_link {
    font-size: 15px; }

.brands-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0; }
  .brands-list_item {
    margin-bottom: 12px; }
    .brands-list_item_link {
      align-items: center;
      border: 1px solid #ededed;
      display: flex;
      height: 80px;
      justify-content: center;
      width: 80px; }
      .brands-list_item_link_image {
        width: 100%; }
  .brands-list_more {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
    text-transform: uppercase; }
  .brands-list_more-items .brands-list_item {
    margin-bottom: 0;
    margin-top: 12px; }

.carousel-indicators {
  bottom: 10px;
  left: auto;
  margin: 0;
  right: 10px;
  width: auto; }
  @media (min-width: 1024px) {
    .carousel-indicators {
      bottom: 35px;
      right: 90px; } }
  @media (min-width: 1024px) {
    .carousel-indicators li {
      border-width: 2px;
      height: 15px;
      width: 15px; } }
  @media (min-width: 1024px) {
    .carousel-indicators .active {
      height: 17px;
      width: 17px; } }

.main-wrapper_content_features {
  background-color: #f3f3f3;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px; }
  @media (min-width: 1024px) {
    .main-wrapper_content_features {
      padding-bottom: 0;
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 1440px) {
    .main-wrapper_content_features {
      padding-bottom: 15px;
      padding-left: 80px;
      padding-right: 80px;
      padding-top: 45px; } }
  .main-wrapper_content_features .features-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0; }
    .main-wrapper_content_features .features-list_item {
      align-items: center;
      display: flex;
      width: 45%;
      margin-bottom: 45px; }
      @media (max-width: 1024px) {
        .main-wrapper_content_features .features-list_item:nth-child(n+7) {
          margin: 0; } }
      @media (min-width: 1024px) {
        .main-wrapper_content_features .features-list_item {
          width: 25%; } }
      .main-wrapper_content_features .features-list_item_link {
        align-items: center;
        display: flex;
        justify-content: center; }
        .main-wrapper_content_features .features-list_item_link_icon {
          fill: #474747;
          height: 30px;
          margin-right: 20px;
          width: 30px; }
          @media (min-width: 1440px) {
            .main-wrapper_content_features .features-list_item_link_icon {
              height: 42px;
              width: 42px; } }
        .main-wrapper_content_features .features-list_item_link_label {
          font-size: 12px;
          line-height: 1.43;
          text-transform: uppercase; }
          @media (min-width: 1024px) {
            .main-wrapper_content_features .features-list_item_link_label {
              font-size: 14px; } }

.sidebar-navigation-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .sidebar-navigation-list_item:not(:last-child) {
    margin-bottom: 5px; }
  .sidebar-navigation-list_item_nav {
    align-items: flex-start;
    display: flex; }
    .sidebar-navigation-list_item_nav_toggle {
      color: #d91111;
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      margin-right: 2px; }
    .sidebar-navigation-list_item_nav_link {
      font-size: 14px;
      font-weight: bold;
      text-decoration: underline;
      text-transform: uppercase; }
  .sidebar-navigation-list-sub {
    list-style: none;
    margin-top: 4px;
    padding-left: 15px; }
    .sidebar-navigation-list-sub_item:not(:last-child) {
      margin-bottom: 5px; }
    .sidebar-navigation-list-sub_item_nav {
      align-items: flex-start;
      display: flex; }
      .sidebar-navigation-list-sub_item_nav_toggle {
        color: #d91111;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        margin-right: 2px; }
      .sidebar-navigation-list-sub_item_nav_link {
        font-size: 14px;
        font-weight: bold;
        text-decoration: underline; }
  .sidebar-navigation-list-sub-sub {
    list-style: none;
    margin-top: 4px;
    padding-left: 18px; }
    .sidebar-navigation-list-sub-sub_item:not(:last-child) {
      margin-bottom: 3px; }
    .sidebar-navigation-list-sub-sub_item_link {
      font-size: 13px;
      text-decoration: underline; }

.product-card {
  display: flex;
  position: relative; }
  @media screen and (min-width: 1024px) {
    .product-card {
      display: block;
      text-align: center; } }
  .product-card-full {
    border-bottom: 1px solid #cbcbcb; }
    div.popover .product-card-full {
      border: 0; }
    @media screen and (min-width: 1024px) {
      .product-card-full {
        border: 2px solid transparent; } }
  .product-card-horizontal {
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left; }
    .product-card-horizontal_wrapper {
      align-items: flex-start;
      display: flex; }
      .product-card-horizontal_wrapper_details {
        margin-right: 50px; }
      .product-card-horizontal_wrapper_buy {
        flex-shrink: 0;
        text-align: center; }
  .product-card_image {
    display: block;
    margin-bottom: 7px; }
    .products_list_horizontal .product-card_image {
      margin-right: 30px;
      width: 250px; }
    .product-card_image_photo {
      display: block;
      width: 100%; }
  @media screen and (min-width: 1024px) {
    .product-card-full:hover {
      background-color: #f3f3f3;
      border-color: #e6e6e6; }
      .product-card-full:hover .product-card_bottom {
        display: block;
        z-index: 10; } }
  div.popover .product-card-full:hover {
    background-color: transparent;
    border: 0; }
  .product-card_name {
    display: none;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px; }
    @media screen and (min-width: 1024px) {
      .product-card_name {
        display: block;
        margin-left: 10px;
        margin-right: 10px; }
        .product-card-full .product-card_name {
          min-height: 78px; }
        div.popover .product-card_name {
          min-height: auto !important; } }
    .product-card_bottom .product-card_name {
      display: block; }
      @media screen and (min-width: 1024px) {
        .product-card_bottom .product-card_name {
          display: none; } }
  .product-card_price-small {
    color: #848181;
    display: block;
    font-size: 13px;
    margin-bottom: 5px; }
  .product-card_price-big_label {
    color: #474747;
    display: block;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase; }
  .product-card_price-big_number {
    color: #474747;
    display: block;
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 5px;
    white-space: nowrap; }
    @media screen and (min-width: 1024px) {
      .product-card_price-big_number {
        font-size: 30px; } }
  @media screen and (min-width: 1024px) {
    .product-card_item-card {
      display: none; } }
  .product-card_wrapper {
    margin-bottom: 10px; }
    @media screen and (min-width: 500px) {
      .product-card_wrapper {
        display: flex; } }
    @media screen and (min-width: 1024px) {
      .product-card_wrapper {
        justify-content: center; } }
  .product-card_stock {
    color: #474747;
    display: block;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 6px;
    margin-right: 7px;
    text-transform: uppercase; }
    @media screen and (min-width: 500px) {
      .product-card_stock {
        margin-bottom: 0; } }
  .product-card_shipping {
    color: #474747;
    display: block;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase; }
  .product-card_button {
    margin-left: auto;
    margin-right: auto; }
    @media screen and (min-width: 1024px) {
      .product-card_button {
        width: auto; } }
  .product-card_top {
    padding-bottom: 15px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 2px; }
    div.popover .product-card_top {
      padding-left: 0;
      padding-right: 0; }
    .product-card-full .product-card_top {
      border-right: 1px solid #cbcbcb;
      min-width: 50%; }
      @media screen and (min-width: 1024px) {
        .product-card-full .product-card_top {
          border-right: 0;
          min-width: auto; } }
    @media screen and (min-width: 1024px) {
      .product-card_top {
        padding-bottom: 0; } }
  .product-card_bottom {
    padding-left: 10px;
    padding-right: 10px;
    text-align: left; }
    div.popover .product-card_bottom {
      background-color: transparent;
      border: 0;
      display: block;
      padding-left: 0;
      padding-right: 0;
      position: static; }
    @media screen and (min-width: 1024px) {
      .product-card_bottom {
        background-color: #f3f3f3;
        border: 2px solid #e6e6e6;
        border-top: 0;
        display: none;
        padding-bottom: 20px;
        padding-left: 23px;
        padding-right: 23px;
        padding-top: 20px;
        position: absolute;
        right: -2px;
        left: -2px; } }
  .product-card_table_col {
    line-height: 1.2;
    padding-bottom: 5px;
    padding-right: 5px; }
    .product-card-horizontal .product-card_table_col {
      padding-right: 10px; }
    .product-card-horizontal .product-card_table_col:nth-child(2) {
      padding-right: 60px; }
  .product-card_more {
    display: block;
    font-size: 13px;
    margin-bottom: 18px;
    margin-top: 15px;
    text-transform: uppercase; }
  .product-card_details {
    color: #474747;
    display: none;
    font-size: 13px;
    line-height: 1.3;
    margin: 0; }
    @media screen and (min-width: 1024px) {
      .product-card_details {
        display: block; } }
    .product-card_details-link {
      display: block;
      margin-top: 10px;
      text-transform: uppercase; }

.site-sidebar_box {
  padding-bottom: 25px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 30px; }
  .search-dropdown .site-sidebar_box {
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px; }
  .site-sidebar_box:not(:last-child) {
    border-bottom: 1px solid #f3f3f3; }
  .site-sidebar_box_title {
    color: #474747;
    display: block;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase; }
    .site-sidebar_box_title:not(:last-child) {
      margin-bottom: 25px; }
  .site-sidebar_box_subtitle {
    color: #474747;
    display: block;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase; }
    .site-sidebar_box_subtitle:not(:last-child) {
      margin-bottom: 14px; }
  .site-sidebar_box_input:not(:last-child) {
    margin-bottom: 6px; }
  .site-sidebar_box_filter-button {
    font-size: 15px;
    margin-top: 10px; }
  .search-dropdown .site-sidebar_box .form-inline .form-group {
    display: flex; }
  .search-dropdown .site-sidebar_box .form-inline .form-group .form-control {
    display: block;
    width: 100%; }
  .search-dropdown .site-sidebar_box .form-inline .form-group .form-control:first-of-type {
    margin-right: 10px; }
